<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At a given temperature, the following reaction took place in a
        <number-value :value="volume" unit="\text{L}" />
        glass container:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="SO2(g) + N2O(g) <=> SO3(g) + N2(g)" />
      </p>

      <p class="mb-3">
        If the equilibrium masses of each of the reactants and products are
        <latex-number :number="so2Mass.toFixed(3)" unit="\text{g}" />
        of
        <chemical-latex content="SO2," />
        <latex-number :number="n2oMass.toFixed(3)" unit="\text{g}" />
        of
        <chemical-latex content="N2O," />
        <latex-number :number="so3Mass.toFixed(3)" unit="\text{g}" />
        of
        <chemical-latex content="SO3," />
        and
        <latex-number :number="n2Mass.toFixed(3)" unit="\text{g}" />
        of
        <chemical-latex content="N2," />
        determine the equilibrium constant,
        <stemble-latex content="$\text{K}_\text{c},$" />
        for the reaction.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question115',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    so2Mass() {
      return this.taskState.getValueBySymbol('so2Mass').content.toFloat();
    },
    so3Mass() {
      return this.taskState.getValueBySymbol('so3Mass').content.toFloat();
    },
    n2oMass() {
      return this.taskState.getValueBySymbol('n2oMass').content.toFloat();
    },
    n2Mass() {
      return this.taskState.getValueBySymbol('n2Mass').content.toFloat();
    },
  },
};
</script>
